import { createAsync } from '@solidjs/router';
import { Title } from '@solidjs/meta';
import { Heading, Tag } from '@troon/ui';
import { Match, Show, Suspense, Switch } from 'solid-js';
import { IconCalendar } from '@troon/icons';
import { gql } from '../../graphql';
import { cachedQuery } from '../../graphql/cached-get';
import { dayTimeToDate } from '../../modules/date-formatting';

export default function Access() {
	const data = createAsync(() => getSubscription({}), { deferStream: true });

	return (
		<div class="flex flex-col gap-8">
			<Title>Troon Access Plans | My account | Troon</Title>
			<Heading as="h1">Troon Access Plans</Heading>

			<div class="flex flex-col gap-6 rounded border border-neutral p-6">
				<Heading as="h2">
					<Tag>Current plan</Tag>
				</Heading>
				<Suspense>
					<Show when={data()?.subscription}>
						{(sub) => (
							<>
								<p class="text-lg font-semibold">{sub().name}</p>
								<Switch>
									<Match when={sub().nextInvoiceDate}>
										<p class="flex flex-row flex-wrap items-baseline gap-2 rounded bg-neutral-100 p-3">
											<IconCalendar class="relative top-1" />
											<span>
												<Show when={sub().nextInvoiceDate}>
													{(date) => <>Your plan will auto-renew on {formatDay(dayTimeToDate(date()))}</>}
												</Show>
												<Show when={sub().nextInvoiceAmount}>
													{(amount) => <> at {amount().displayValue} per year</>}
												</Show>
												.
											</span>
										</p>
									</Match>
									<Match when={sub().endDayTime}>
										{(endDate) => (
											<p class="flex flex-row flex-wrap items-baseline gap-2 rounded bg-neutral-100 p-3 text-red-600">
												<IconCalendar class="relative top-1" />
												<span>Your plan is set to expire on {formatDay(dayTimeToDate(endDate()))}.</span>
											</p>
										)}
									</Match>
								</Switch>
							</>
						)}
					</Show>
				</Suspense>
			</div>
		</div>
	);
}

const activityQuery = gql(`
query activeSubscription {
	subscription: activeTroonCardSubscription {
		id
		status
		name: subscriptionName
		startDayTime {
			...DayTime
		}
		endDayTime {
			...DayTime
		}
		totalAmount {
			...Currency
		}
		nextInvoiceAmount {
			...Currency
		}
		nextInvoiceDate {
			...DayTime
		}
	}
}`);

const getSubscription = cachedQuery(activityQuery);

const formatDay = new Intl.DateTimeFormat('en-us', { month: 'long', day: 'numeric', year: 'numeric' }).format;
